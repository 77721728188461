<template>
  <div class="h-full">
    <Html class="h-full bg-gray-100">

    <Head>
      <Title>WeTix Console</Title>
    </Head>

    </Html>

    <Body class="h-full"></Body>
    <NuxtLoadingIndicator />
    <TenantAdminDevBanner v-show="showDevBar" />
    <div v-if="authState.isAuthed">
      <Disclosure as="header" class="bg-white shadow" v-slot="{ open }">
        <div class=" max-w-7xl mx-auto px-2 sm:px-4 lg:divide-y lg:divide-gray-200 lg:px-8">
          <div class="relative h-16 flex justify-between">
            <div class="relative z-10 px-2 flex lg:px-0">
              <div class="flex-shrink-0 flex items-center">
                <img class="block h-8 w-auto " src="~/assets/wetix-logo-text-navy.svg" alt="WeTix" />
                <LayoutAppSwitcher active-app-name="Tickets" />
              </div>

            </div>
            <div class="relative z-0 flex-1 px-2 flex items-center justify-center md:absolute md:inset-0">
              <div class="max-w-xs w-full">
                <label for="search" class="sr-only">Search</label>
                <div class="relative">
                  <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                    <SearchIcon class="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input id="wetix-global-search-input" name="search" @click="searchStore.openSearchOverlay()"
                    class="block w-full bg-white border border-gray-300 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 focus:ring-gray-900 focus:border-gray-900 sm:text-sm"
                    placeholder="Search (ctrl+K)" type="search" />
                </div>
              </div>
            </div>
            <div class="relative z-10 flex items-center lg:hidden">
              <!-- Mobile menu button -->
              <DisclosureButton
                class="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-900">
                <span class="sr-only">Open menu</span>
                <MenuIcon v-if="!showMenu" @click="showMenu = true" class="block h-6 w-6" aria-hidden="true" />
                <XIcon v-else class="block h-6 w-6" aria-hidden="true" />
              </DisclosureButton>
            </div>
            <div class="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">

              <LayoutQuickAccessMenu />

              <NavTenantSwitcher />

              <NavNotificationsButton />

              <!-- Profile dropdown -->
              <LayoutUserProfileMenu />
            </div>
          </div>
          <nav class="hidden lg:py-2 lg:flex lg:space-x-8" aria-label="Global">
            <NuxtLink v-for="item in navigation" :key="item.name" :to="item.href"
              active-class="bg-gray-100 text-primary-600"
              class="rounded-md py-2 px-3 inline-flex items-center text-sm font-medium text-gray-900 hover:bg-gray-50 hover:text-gray-900">
              {{ item.name }}
            </NuxtLink>
          </nav>
        </div>
      </Disclosure>

      <!-- Hamburger menu for mobile -->
      <LayoutMenuDrawer class="lg:hidden" :links="navigation" :user-navigation="userNavigation" :auth-state="authState"
        :show-menu="showMenu" @close-menu="showMenu = false" />

      <!-- MAIN PAGE SLOT -->
      <div class="min-h-screen">
        <NuxtPage />
      </div>


      <!-- FOOTER -->
      <LayoutFooterAppGeneral />

    </div>
    <!-- OVERLAYS / OUT OF VIEW -->
    <client-only>
      <SearchOverlay></SearchOverlay>
    </client-only>
    <NotificationBasic></NotificationBasic>
  </div>
</template>

<script setup lang="ts">
import { invoke, until } from '@vueuse/core'

import {
  Disclosure,
  DisclosureButton,
} from "@headlessui/vue";
import {
  BellIcon,
  HomeIcon,
  TicketIcon,
  UserIcon,
  CogIcon,
  InformationCircleIcon,
  ClipboardListIcon,
  CollectionIcon,
  SaveIcon,
  DotsVerticalIcon,
  XIcon,
  MenuIcon,
} from "@heroicons/vue/outline";
import { SearchIcon } from "@heroicons/vue/solid";

// Import stores
import { useSearchStore } from "~/stores/search";
import { useAuthState } from "~/stores/auth";
import { useGlobalAuthToken } from "~/stores/globalAuthToken";
import { useNotificationState } from "~/stores/notifications";

// Import useActiveElement from vueUse to activate the global search dialog when search box is clicked
// Use onKeyStroke to launch the command box when ctrl + / is pressed.
import { useActiveElement, useMagicKeys } from '@vueuse/core'


// All admin pages should be passed through the authCheck middleware.
// definePageMeta({
//   // Use the auth middleware to check where the user should be redirected to after logging in.
//   // middleware: ["authadminonly"]
// })

// Nuxt app context
const { $loading, ssrContext, $firebaseAuth } = useNuxtApp()

// Global auth token
const globalAuthToken = useGlobalAuthToken()

// Auth state
const authState = useAuthState()

let authHasUser
if (!ssrContext) authHasUser = $firebaseAuth?.currentUser

// Composable to watch ID token and set cookie.
useIDTokenChange()

// On page load, we want to clear the current wetix-token cookie and only render the page once it's present.
const token = useCookie('wetix-token', {
  path: '/',
})

// console.warn(`PAGE LOAD. Token: ${token.value}`)

// If there's no token, it's expired and we should wait until it refreshes.
if (!token.value) {
  // Show the loading overlay
  // Clear the globalAuthToken, because it's reactive so we can wait for it to update.
  globalAuthToken.value = null
  invoke(async () => {
    if (!ssrContext) {
      // let refreshTokenLoaderIndicator = $loading.show()

      await until(authState.isAuthed).toBeTruthy({ timeout: 5000 })
      // refreshTokenLoaderIndicator.hide()
    }
  })
}

// Pinia search store
const searchStore = useSearchStore()

// Pinia notification store
const notificationStore = useNotificationState()

// Config
const config = useRuntimeConfig()

// Listen for ctrl + / keystroke to launch the command box
const keys = useMagicKeys()
const ctrlForwardslash = keys['Ctrl+K']
watch(ctrlForwardslash, (v) => {
  if (v)
    console.log('Ctrl + / have been pressed. ')
  if (v && searchStore.showGlobalSearchOverlay) {
    console.log('Closing global search box')
    searchStore.closeSearchOverlay()
  } else if (v && !searchStore.showGlobalSearchOverlay) {
    console.log('Opening global search box')
    searchStore.openSearchOverlay()
  }
})

const showMenu = ref(false)

const showDevBar = useCookie('wetix-dev-mode')

const navigation = [
  {
    name: "Dashboard",
    href: "/app/tenant-admin",
    icon: HomeIcon,
    lineBreak: true,
  },
  {
    name: "Events",
    href: "/app/tenant-admin/events",
    icon: ClipboardListIcon,
  },
  {
    name: "Orders",
    href: "/app/tenant-admin/orders",
    icon: CollectionIcon,

  },
  {
    name: "Customers",
    icon: UserIcon,
    href: "/app/tenant-admin/customers",
    lineBreak: true,
  },
  {
    name: "Coupons",
    icon: TicketIcon,
    href: "/app/tenant-admin/coupons",
    lineBreak: true,
  },
  {
    name: "Integrations",
    href: "/app/tenant-admin/integrations",
    icon: SaveIcon,
    current: false,
  },
  {
    name: "Settings",
    href: "/app/tenant-admin/settings",
    icon: CogIcon,
    current: false,
  },
  {
    name: "Help",
    href: "/app/tenant-admin/help",
    icon: InformationCircleIcon,
    current: false,
  },
  {
    name: "More",
    href: "/app/tenant-admin/more/emails",
    icon: DotsVerticalIcon,
    current: false,
  },
]

const userNavigation = [
  { name: 'Profile', href: '/app/user/profile' },
  { name: 'User Portal', href: '/app/user' },
  { name: 'Messages', href: '/app/tenant-admin/messages' },
  { name: 'Settings', href: '/app/tenant-admin/settings' },
  // { name: 'Sign out', href: '/app/sign-out' },
]

</script>


<style scoped>
hr {
  border-top: 1px solid #444fb6;
}
</style>